import { ThemeProvider, Themed } from 'theme-ui'
import Global from '../components/Global'
import theme from '../helpers/theme'
import Head from 'next/head'

function MyApp({ Component, pageProps, router }) {
  return (
    <ThemeProvider theme={theme}>
      <Global />
      <Head>
        <title>Odeon TV</title>
        <meta name="description" content="Odeon Menu TVs" />
        <meta name="robots" content="noindex" />
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />

        <link
          href="/icon/favicon-16x16.png"
          rel="icon"
          type="image/png"
          sizes="16x16"
        />
        <link
          href="/icon/favicon-32x32.png"
          rel="icon"
          type="image/png"
          sizes="32x32"
        />
        <link
          href="/icon/apple-icon-180x180.png"
          rel="apple-touch-icon"
        />
        <meta name="theme-color" content="#000" />
        <link rel="manifest" href="/manifest.json" />
        <style
          dangerouslySetInnerHTML={{
            __html: `
            @font-face {
              font-family:"gtwalsheim";
              src: url('/fonts/gtwalsheim-bold.woff2') format('woff2'),
              url('/fonts/gtwalsheim-bold.woff') format('woff');
              font-display:block;font-style:regular;font-weight:700;
            }
          `
          }}
        />
        <style
          dangerouslySetInnerHTML={{
            __html: `
            @font-face {
              font-family:"gtwalsheim";
              src: url('/fonts/gtwalsheim-medium.woff2') format('woff2'),
              url('/fonts/gtwalsheim-medium.woff') format('woff');
              font-display:block;font-style:regular;font-weight:500;
            }
          `
          }}
        />
      </Head>
      <Themed.root>
        <Component {...pageProps} key={router.route} />
      </Themed.root>
    </ThemeProvider>
  )
}

export default MyApp
